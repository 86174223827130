<template>
  <div class="rc-row">
    <div v-if="left" :class="leftPadding"> </div>
    <div :class="middle" ref="check">
      <div class="rc-container">
        <div class="rc-row">
          <div v-if="isTitleShowing" class="rc-box-col-13 rc-text-key">
            {{ placeholderText() }}
          </div>
          <div class="rc-box-col-2 rc-text-right rc-text-middle" style="padding: 2px">
            <input :placeholder="placeholderText()" :disable="disabled" type="checkbox" v-model="value"
              v-on:keyup.enter="enter" v-on:input="withValue" style="transform: scale(1.75)" />
          </div>
        </div>
      </div>
      <div v-if="!shouldShowRequired" class="rc-font-small rc-text-value"><a :href="hintUrl">{{ hint }}</a> <br /></div>
      <div v-if="shouldShowRequired" class="rc-font-small rc-background-required">A value for {{ placeholderText() }} is
        required</div>
    </div>
    <div v-if="right" :class="rightPadding"> </div>
  </div>
</template>

<script>

import Catelog from "@/domain/session/CanadianEnglish.js";
import Catalog from '@/domain/session/Catalog.js';
import StringUtils from '@/utils/StringUtils';

export default {
  name: 'key-date',
  components: {
  },
  props: {
    title: String,
    hint: String,
    hintUrl: { type: String, default: "" },
    placeholder: String,
    defaultValue: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    left: { type: Number, default: 0 },
    right: { type: Number, default: 0 },
    mandatory: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    address: { type: String, default: null },
    addressLabel: String,
    styling: { type: String, default: "" },
  },
  data() {
    return {
      value: this.defaultValue,
      isTitleShowing: true,
      Catelog: new Catelog(),
      C: new Catalog(),
      StringUtils: StringUtils,
    };
  },
  watch: {
    value() {
      this.validate();
      return this.value;
    },
    required() {
      if (this.shouldShowRequired) {
        this.$refs.check.scrollIntoView();
      }
    },
    defaultValue() {
      this.value = this.defaultValue;
    },
  },
  computed: {
    shouldShowRequired: function () {
      if (this.required) {
        if (this.mandatory) {
          if (this.value == false) {
            return true;
          }
        }
      }
      return false;
    },
    leftPadding: function () {
      if (this.left > 0) {
        return "rc-box-col-" + this.left;
      }
      return "";
    },
    rightPadding: function () {
      if (this.right > 0) {
        return "rc-box-col-" + this.right;
      }
      return "";
    },
    middle: function () {
      var value = (15 - this.left - this.right);
      return "rc-box-col-" + value + " " + this.styling;
    },
  },
  mounted: function () {
    this.validate();
  },
  methods: {
    placeholderText: function () {
      if (this.mandatory) {
        return this.Catelog.get(this.placeholder) + "*";
      }
      return this.Catelog.get(this.placeholder);
    },
    withValue: function () {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(this.emit, 500);
    },
    emit: function () {
      clearInterval(this.timer);
      this.$emit('value', { 'key': this.title, 'value': this.value });
    },
    validate: function () {
    },
    enter: function () {
      this.$emit('enter', this.title);
    },
  },

}
</script>
