<template>
  <Row v-if="ready">
    <div v-if="left" :class="leftPadding"> </div>
    
    <div :class="middle" ref="date">
      <Box>
        <Row>
          <div v-if="isTitleShowing" class="rc-font-small rc-text-key">{{ Catelog.get(placeholder) }}</div>
          <div v-else class="rc-font-small"> <br> </div>
        </Row>
        <Row>
          <Column :width="14">
            <input class="rc-input-field rc-input-width rc-text-value"
              :placeholder="placeholderText()" 
              :disable="disabled"
              type="date" 
              v-model="value"
              v-on:keyup.enter="enter"
              v-on:click="click"
              v-on:change="change"
              v-on:input="input" 
              style="height: 30px; padding-left: 20px; padding-right: 20px;"
            />
          </Column>
          <Column :width="1" class="clickable">
            <i v-if="showClear" v-on:click="clear()" class="fa fa-close"/> 
          </Column>
        </Row>
        <Row>
          <div v-if="!shouldShowRequired" class="rc-font-small rc-text-value">{{ hint }} <br /></div>
          <div v-if="shouldShowRequired" class="rc-font-small rc-background-required">A value for {{ C.get(placeholder) }} is required</div>
        </Row>
      </Box>
    </div>
   
   <div v-if="right" :class="rightPadding"> </div>
   
 </Row>
</template>

<script>
import { mapGetters } from 'vuex'

import Catelog from '@/domain/session/CanadianEnglish.js';
import ContentUtils from '@/utils/ContentUtils';
import StringUtils from '@/utils/StringUtils';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'key-date',
  components: {
    Box, Row, Column,
  },
  props: {
    title: String,
    hint: String,
    defaultValue: String,
    placeholder: String,
    disabled: {type: Boolean, default: false},
    left:     { type: Number, default : 0 },
    right:    { type: Number, default : 0 },
    mandatory: {type: Boolean, default: false},
    showClear: {type: Boolean, default: true},
    required: {type: Boolean, default: false},
  },
  data() {
    return {
      value: this.defaultValue ? ContentUtils.decodeDate(this.defaultValue) : null,
      isTitleShowing: true,
      Catelog: new Catelog(),
      isOpen: false,
      ready: false,
    };
  },
  watch: {
    value() {
      this.validate();
    },
    required() {
      if (this.required) {
        this.$refs.date.scrollIntoView();
      }
    },
    defaultValue() {
      this.value = this.defaultValue ? ContentUtils.decodeDate(this.defaultValue) : null;
    },
  },
  mounted: function() {
    this.validate();
    this.ready = true;
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
                 
    catelog: function() {
      if (this.domain) {
        return this.domain.lang();
      }
      return new Catelog();
    },
    leftPadding: function() {
      if (this.left > 0) {
        return "rc-box-col-" + this.left;
      }
      return "";
    },
    rightPadding: function() {
      if (this.right > 0) {
        return "rc-box-col-" + this.right;
      }
      return "";
    },
    middle: function() {
      var value = (15 - this.left - this.right);
      return "rc-box-col-" + value;
    },
    shouldShowRequired: function() {
      if (this.required) {
        if (this.mandatory) {
          if (StringUtils.isEmpty(this.value)) {
            return true;
          }
        }
      }
      return false;
    },
  },
  methods: {
    placeholderText: function() {
      if (this.mandatory) {
        return this.Catelog.get(this.placeholder) + "*";
      }
      return this.Catelog.get(this.placeholder);
    },
   
    emit: function() {
      clearInterval(this.timer);
      const value = ContentUtils.encodeDate(this.value);
      this.$emit('value', {'key': this.title, 'value': value});
    },
    validate: function() {
    },
    click: function(event) {
      if (!event) {
        return;
      }
      this.domain.logger().info("click");
    },
    change: function(event) {
      if (!event) {
        return;
      }
      this.domain.logger().info("change");
      this.emit();
    },
    input: function(event) {
      if (!event) {
        return;
      }
      this.domain.logger().info("input");
    },
    enter: function() {
      this.emit();
    },
    clear: function() {
      this.value = null;
      this.emit();
    }
  },

}
</script>
