<template>
  <div class="row" cmpt="KeyTextArea">
    <div v-if="left" :class="leftPadding"> </div>
    <div :class="middle" ref="value">
      <div v-if="isTitleShowing" class="rc-text-label rc-font-small">
        {{ C.get(placeholder) }}
      </div>
      <div v-else class="rc-font-small">
        <div v-if="placeholder != null" > 
          <br> 
        </div>
      </div>
      <textarea :rows="rows" class="rc-input-field rc-input-width rc-text-value"
        :placeholder="placeholderText()" 
        :disable="disabled"
        v-model="value"
        v-on:keyup="withValue" 
        style="min-height: 30px; padding-left: 20px; padding-right: 20px;"
        />
      <div v-if="hint" class="rc-font-small rc-text-value">{{ hint }}</div>
      <div v-if="required" class="rc-font-small rc-background-required">A value for {{ C.get(placeholder) }} is required</div>
    </div>
    <div v-if="right" :class="rightPadding"> </div>
  </div>
</template>

<script>
import ContentUtils from '@/utils/ContentUtils';
import Catalog from '@/domain/session/Catalog.js';

export default {
  name: 'key-textarea',
  components: {
  },
  props: {
    title: String,
    rows: {type: Number, default: 1},
    defaultValue: String,
    placeholder: String,
    isEmail: {type: Boolean, default: false},
    hint: String,
    type: String,
    disabled: {type: Boolean, default: false},
    left: {type: Number, default: 0},
    right: {type: Number, default: 0},
    mandatory: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    clearValue: {type: Boolean, default: false},
  },
  data() {
    return {
      value: null,
      isTitleShowing: false,
      C: new Catalog(),
      timer: null,
    };
  },
  watch: {
    value() {
      this.validate();
    },
    clearValue() {
      if (this.clearValue) {
        this.value = null;
      }
    },
    required() {
      if (this.required) {
        this.$refs.value.scrollIntoView();
      }
    },
  },
  computed: {
    initialValue: function() {
      if (this.isEmail) {
        return this.defaultValue;
      }
      return this.defaultValue ? 
          ContentUtils.unescape(this.defaultValue) : 
          this.defaultValue;
    },
    leftPadding: function() {
      if (this.left > 0) {
        return "rc-box-col-" + this.left;
      }
      return "";
    },
    rightPadding: function() {
      if (this.right > 0) {
        return "rc-box-col-" + this.right;
      }
      return "";
    },
    middle: function() {
      var value = (15 - this.left - this.right);
      var css = "rc-box-col-" + value;
      return css;
    },
  },
  mounted: function() {
    this.value = this.initialValue;
    this.validate();
  },
  methods: {
    placeholderText: function() {
      if (this.mandatory) {
        return this.C.get(this.placeholder) + "*";
      }
      return this.C.get(this.placeholder);
    },
    withValue: function() {
      this.emit();
    },
    emit: function() {
      clearInterval(this.timer)
      this.$emit('value', {'key': this.title, 'value': ContentUtils.encode(this.value)})
    },
    validate: function() {
      if (this.value != null && this.value.trim().length > 0) {
        this.isTitleShowing = true;
      } else {
        this.isTitleShowing = false;
      }
      if (this.mandatory) {
        this.$emit('requirementsMet', false);
      }
    },
    enter: function() {
      this.emit();
      this.$emit('enter', this.title);
    }
  },

}
</script>
