<template>
  <Row v-if="ready">
    <div v-if="left" :class="leftPadding"> </div>
    <div :class="middle" ref="list">
      <Box>
        <Row>
          <div v-if="isTitleShowing" class="rc-font-small rc-text-label">
            {{ placeholderText() }}
          </div>
          <div v-else class="rc-font-small">
            <div>
              <br>
            </div>
          </div>

          <div class="rc-text-xxtiny"> <br> </div>
        </Row>
        <Row v-if="showClear">
          <Column :width="14">
            <select v-model="value" v-on:change="withValue" class="rc-input-field rc-input-width rc-text-value"
              style="height: 30px; padding-left: 20px; padding-right: 20px;">
              <option disabled class="rc-text-primary" value="" :selected="true">Please select one</option>
              <option class="rc-text-primary" v-for="(option) in values" :selected="isSelected(value, option)"
                :value="option['@rid']" :key="option['@rid']">{{ option.name }} {{ Strings.toString(option.summary) }}
              </option>
            </select>
          </Column>
          <Column :width="1" class="clickable">
            <i v-on:click="clear()" class="fa fa-close" />
          </Column>
        </Row>
        <Row v-else>
          <Column :width="15">
            <select v-model="value" v-on:change="withValue" class="rc-input-field rc-input-width rc-text-value"
              style="height: 30px; padding-left: 20px; padding-right: 20px;">
              <option disabled class="rc-text-primary" value="" :selected="true">Please select one</option>
              <option class="rc-text-primary" v-for="(option) in values" :selected="isSelected(value, option)"
                :value="option['@rid']" :key="option['@rid']">{{ option.name }} {{ Strings.toString(option.summary) }}
              </option>
            </select>
          </Column>
        </Row>
        <Row>
          <div v-if="optionDetails" class="rc-text-small rc-text-value"><b>Description</b> {{ optionDetails }}</div>

          <div v-if="optionDetails">
            <Box>
              <Row>
                <Column :width="15">
                </Column>
              </Row>
            </Box>
          </div>

          <Box>
            <Row v-if="!shouldShowRequired && !Strings.isEmpty(hint)">
              <Column :width="15">
                <div class="rc-font-small rc-text-value">{{ hint }} <br /></div>
              </Column>
            </Row>
            <Row v-if="shouldShowRequired">
              <Column :width="15">
                <div v-if="shouldShowRequired" class="rc-font-small rc-background-required">A value for {{ C.get(placeholder) }} is required</div>
              </Column>
            </Row>
          </Box>

        </Row>
      </Box>
    </div> <!-- end middle -->
    <div v-if="right" :class="rightPadding"> </div>
  </Row> <!-- end row -->
</template>

<script>
import ContentUtils from '@/utils/ContentUtils';
import StringUtils from '@/utils/StringUtils';
import Catelog from '@/domain/session/CanadianEnglish.js';
import Catalog from '@/domain/session/Catalog.js';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'key-value',
  components: {
    Box, Row, Column,
  },
  props: {
    componentName: { type: String, default: "" },
    title: String,
    hint: String,
    defaultValue: String,
    placeholder: String,
    mandatory: { type: Boolean, default: false },
    showClear: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    options: { type: Array, default: function () { return []; } },
    left: { type: Number, default: 0 },
    right: { type: Number, default: 0 },
  },
  data() {
    return {
      value: this.defaultValue ? ContentUtils.unescape(this.defaultValue) : this.defaultValue,
      isTitleShowing: true,
      Catelog: new Catelog(),
      C: new Catalog(),
      optionDetails: null,
      Strings: StringUtils,
      ready: false,
    };
  },
  watch: {
    required() {
      if (this.required) {
        this.$refs.list.scrollIntoView();
      }
    },
    value() {
      this.optionDetails = null;
      for (var index = 0; index < this.options.length; index++) {
        var option = this.options[index];
        if (option['@rid'] === this.value && option.details) {
          this.optionDetails = StringUtils.toString(option.summary) + " " + StringUtils.toString(option.details);
          break;
        }
      }
    },
    defaultValue() {
      this.value = this.defaultValue ? ContentUtils.unescape(this.defaultValue) : this.defaultValue;
    }
  },
  computed: {
    shouldShowRequired: function () {
      if (this.required) {
        if (this.mandatory) {
          if (StringUtils.isEmpty(this.value)) {
            return true;
          }
        }
      }
      return false;
    },
    leftPadding: function () {
      if (this.left > 0) {
        return "rc-box-col-" + this.left;
      }
      return "";
    },
    rightPadding: function () {
      if (this.right > 0) {
        return "rc-box-col-" + this.right;
      }
      return "";
    },
    middle: function () {
      var value = (15 - this.left - this.right);
      return "rc-box-col-" + value;
    },
    values: function () {
      return this.options;
    },
  },
  mounted: function () {
    if (this.componentName == "companyRole" || this.componentName === "companyProvince") {
      window.console.log(this.options);
      window.console.log(this.defaultValue);
    }
    this.validate();
    this.ready = true;
  },
  methods: {
    isSelected(value, option) {
      var id = option["@rid"];
      var selected = value === id;
      if (this.componentName == "companyRole") {
        window.console.log("value=" + value + " id=" + id + " default=" +  this.defaultValue + " selected=" + selected);
      }
      return selected;
    },

    placeholderText: function () {
      var value = this.Catelog.get(this.placeholder);
      if (this.mandatory) {
        value += "*";
      }
      return value;
    },

    withValue: function () {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(this.emit, 250);
    },

    emit: function () {
      clearInterval(this.timer);
      this.$emit('value', { 'key': this.title, 'value': ContentUtils.escape(this.value) });
    },

    clear: function () {
      this.value = null;
      this.emit();
    },

    validate: function () {
      this.optionDetails = null;
      for (var index = 0; index < this.options.length; index++) {
        var option = this.options[index];
        if (option['@rid'] === this.value && option.details) {
          this.optionDetails = StringUtils.toString(option.summary) + " " + StringUtils.toString(option.details);
          break;
        }
      }
    },
    enter: function () {
      this.$emit('enter', this.title);
    }
  },

}
</script>
